import { loadDocumentTypeConfigs } from '@fv/models'

import { DateFilter } from '../../components/CustomFilterForm/DateFilter'
import {
  dateOptions,
  directionOptions,
  pastDateOptions,
  pricingMethodOptions,
  pricingTypeOptions,
  shippingModeOptions,
  yesNoOptions,
} from '../../components/CustomFilterForm/filterOptions'
import { type FilterConfig } from '../../components/CustomFilterForm/types'
import { useUserSettings } from '../../hooks/settings'

export const pickupDateFilter: FilterConfig = {
  FilterComponent: DateFilter,
  label: 'Pickup date',
  name: 'pickupDate',
  options: dateOptions,
}
export const bookedDateFilter: FilterConfig = {
  FilterComponent: DateFilter,
  label: 'Booked date',
  name: 'bookedDate',
  options: pastDateOptions,
}
export const deliveryDateFilter: FilterConfig = {
  FilterComponent: DateFilter,
  label: 'Delivery date',
  name: 'deliveryDate',
  options: dateOptions,
}
export const directionFilter: FilterConfig = {
  label: 'Direction',
  name: 'direction[]',
  options: directionOptions,
}

export const shippingModeFilter: FilterConfig = {
  label: 'Shipping mode',
  name: 'mode[]',
  options: shippingModeOptions,
}

export const isFreightCollectFilter: FilterConfig = {
  label: 'Bill Recipient / 3rd Party',
  name: 'isFreightCollect',
  options: yesNoOptions,
}
export const pricingMethodFilter: FilterConfig = {
  label: 'Pricing Method',
  name: 'pricingMethod[]',
  options: pricingMethodOptions,
}
export const interlineFilter: FilterConfig = {
  label: 'Interline',
  name: 'isInterline',
  options: yesNoOptions,
}
export const pricingTypeFilter: FilterConfig = {
  label: 'Pricing Type',
  name: 'pricingType[]',
  options: pricingTypeOptions,
}
export const liveLoadFilter: FilterConfig = {
  label: 'Live load',
  name: 'isLiveLoad',
  options: yesNoOptions,
}
export const archivedFilter: FilterConfig = {
  label: 'Archived',
  name: 'isArchived',
  options: yesNoOptions,
}

export const spotFilter: FilterConfig = {
  label: 'Spot Quote',
  name: 'isSpot',
  options: yesNoOptions,
}

export const hasDocumentFilter: FilterConfig = {
  label: 'Has document',
  name: 'hasDocs[]',
  options: loadDocumentTypeConfigs
    .filter(c => c.allowedSources.some(s => s === 'shipper' || s === 'carrier'))
    .map(o => ({
      text: o.name,
      value: o.key,
    })),
}

export const missingDocumentFilter: FilterConfig = {
  label: 'Missing document',
  name: 'missingDocs[]',
  options: loadDocumentTypeConfigs
    .filter(c => c.allowedSources.some(s => s === 'shipper' || s === 'carrier'))
    .map(o => ({
      text: o.name,
      value: o.key,
    })),
}

export const useAccountFilters = () => {
  const { data: settings } = useUserSettings()
  const { carriers, locations, tags, users, removedLocations } = settings ?? {}
  const userOptions = (users ?? []).map(u => ({ text: u.label, value: u._id }))
  const removedLocationOptions =
    removedLocations?.map(l => {
      return {
        ...l,
        label: `REMOVED - ${l.label}`,
      }
    }) ?? []
  const carrierFilter: FilterConfig = {
    label: 'Carrier',
    name: 'carrier[]',
    options: (carriers ?? []).map(c => ({ text: c.label, value: c._id })),
  }
  const quotedByFilter: FilterConfig = {
    label: 'Quoted by',
    name: 'quotedBy[]',
    options: userOptions,
  }
  const bookedByFilter: FilterConfig = {
    label: 'Booked by',
    name: 'bookedBy[]',
    options: userOptions,
  }
  const tagFilter: FilterConfig = {
    label: 'Tag',
    name: 'tag[]',
    options: (tags ?? []).map(t => ({ text: t.label, value: t._id })),
  }
  const locationFilter: FilterConfig = {
    label: 'Account location',
    name: 'location[]',
    options: [...(locations ?? []), ...removedLocationOptions].map(x => ({
      text: x.label,
      value: x._id,
    })),
  }

  return {
    carrierFilter,
    quotedByFilter,
    bookedByFilter,
    tagFilter,
    locationFilter,
  }
}
