import { passwordRegex } from '@fv/models/marketing'

const alphaNumericRegex = /^[a-z0-9]*$/i
export const isAlphaNumeric = (s: string) => !!alphaNumericRegex.exec(s)

// From legacy FV
export const emailRegExpMatcher =
  /[a-zA-Z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/g

export const validatePasswordStrength = (password: string) => {
  return !!password.match(passwordRegex)
}
