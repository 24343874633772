import { DateRange, dateRangeNames, PredefinedDateRange } from '@fv/models'
import { dates, HintedString } from '@fv/models/core'

export type DateRangeValue = HintedString<DateRange>
export type ParsedDateRange = {
  name: PredefinedDateRange | 'custom'
  isCustom: boolean
  start?: Date
  end?: Date
}

export const parseDateRange = (
  value: string | null | undefined,
  defaultRange: PredefinedDateRange = 'last-7-days',
): ParsedDateRange => {
  if (!value) {
    return {
      name: defaultRange,
      isCustom: false,
      start: undefined,
      end: undefined,
    }
  }

  const isCustom = !dateRangeNames.includes(value as PredefinedDateRange)
  let start: Date | undefined = undefined
  let end: Date | undefined = undefined
  if (isCustom) {
    const parsed = dates.parseDateRange(value)
    start = parsed.start
    end = parsed.end
  }

  return {
    name: isCustom ? 'custom' : (value as PredefinedDateRange),
    isCustom,
    start,
    end,
  }
}
