import { useQuery } from '@tanstack/react-query'

import { type SubscriptionContextResponse } from '@fv/models'

import { shipperFetch } from '../../../utils/shipperFetch'
import { useAccountFeatures } from '../../auth'

export const useSubscriptionContextQuery = () => {
  const { subscriptionsV2 } = useAccountFeatures()
  return useQuery(
    ['subscription'],
    () => shipperFetch<SubscriptionContextResponse>('/subscription'),
    {
      enabled: subscriptionsV2,
    },
  )
}
