import sortBy from 'lodash/sortBy'
import uniqBy from 'lodash/uniqBy'
import { useMemo } from 'react'

import { statesByCode } from '@fv/client-types'

import { useQuoteSettings } from '../../../hooks/settings'
import { useSpotQuoteContacts } from '../../spot-quote-contacts/queries'
import { type ContractedRatesFilter } from './ListFilter'
import { type ContractedRate, type EquipmentTypeOption } from './types'

export function useCarrierOptions() {
  const contactsQuery = useSpotQuoteContacts()
  return {
    ...contactsQuery,
    data: sortBy(
      uniqBy(
        contactsQuery.data?.filter(c => !!c.carrierId) ?? [],
        c => c.carrierId,
      ).map(g => ({
        text: g.carrierName,
        value: g.carrierId,
      })),
      i => i.text,
    ),
  }
}

export function useEquipmentOptions(): EquipmentTypeOption[] {
  const settingsQuery = useQuoteSettings('truckload')

  return useMemo(() => {
    const types = settingsQuery.data?.equipmentOptions ?? []

    return types.map(t => ({
      label: t.name,
      value: t.key,
    }))
  }, [settingsQuery.data?.equipmentOptions])
}

export function useFilteredRates(
  filters: ContractedRatesFilter,
  rates?: ContractedRate[],
) {
  if (!rates?.length) return []

  const { contractSearch, locSearch } = filters
  if (!contractSearch && !locSearch) return rates

  return rates.filter(r => {
    if (!contractSearch && !locSearch) return true

    const contractMatch =
      contractSearch &&
      contractSearch.toLowerCase() === r.contractNumber?.toLowerCase()
    const zipMatch = locSearch && rateMatchesZipSearch(r, locSearch)

    if (contractSearch && !locSearch) return contractMatch
    if (!contractSearch && locSearch) return zipMatch
    return contractMatch && zipMatch
  })
}

const rateMatchesZipSearch = (rate: ContractedRate, search: string) => {
  const exactMatch = [
    rate.origin.postalCode,
    rate.origin.postalCodeEnd,
    rate.origin.state,
    rate.origin.state ? statesByCode[rate.origin.state] : undefined,
    rate.destination.postalCode,
    rate.destination.postalCodeEnd,
    rate.destination.state,
    rate.destination.state ? statesByCode[rate.destination.state] : undefined,
  ]
    .filter(r => r)
    .map(r => r?.toLowerCase())
    .includes(search.toLowerCase())

  const rangeMatch =
    rate.destination.postalCode &&
    rate.destination.postalCodeEnd &&
    Number(search) &&
    Number(rate.destination.postalCode) <= Number(search) &&
    Number(search) <= Number(rate.destination.postalCodeEnd)

  return exactMatch || rangeMatch
}
