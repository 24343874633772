import { useState } from 'react'
import toast from 'react-hot-toast'

import { FvButton, FvLinkButton, ValidatedForm } from '@fv/client-components'
import { marketingUri } from '@fv/client-core'

import InfoBox from '../../../components/InfoBox'
import { InputGroup } from '../../../components/inputs/InputGroup'
import { FedexError } from './FedexError'
import {
  useFxfeProvisionOptionMutation,
  useFxfeSetStep,
  useFxfeToken,
  useFxfeWizardContext,
} from './hooks'
import {
  type FxfeProvisionResponse,
  type MfaOptionItem,
  type MfaRequestType,
} from './types'

const labelGenerator = (option: MfaOptionItem) => {
  if (option.type === 'invoice') {
    return 'Enter details from parcel invoice within the last 90 days'
  } else if (option.type === 'support') {
    return 'Call FedEx Support and verify your identity.'
  } else if (option.type === 'email') {
    return `Email me a PIN at ${option.value ?? 'the email address on file'}`
  } else if (option.type === 'call') {
    return `Receive an automated call with the PIN at ${option.value}`
  } else {
    return `Text me a PIN at ${option.value}`
  }
}

export const FedexMfaOptions = () => {
  const {
    options: [options],
    selectedOption: [wizardOption, setWizardOption],
  } = useFxfeWizardContext()
  const accountToken = useFxfeToken()
  const setStep = useFxfeSetStep()
  const [error, setError] = useState<FxfeProvisionResponse>()
  const [selected, setSelected] = useState<MfaRequestType | undefined>(
    wizardOption?.type,
  )
  const allOptions = [...options].concat({ type: 'support' })
  const mutation = useFxfeProvisionOptionMutation()

  const handleSubmit = async () => {
    if (!selected) return
    setWizardOption(allOptions.find(o => o.type === selected))
    if (selected === 'invoice' || selected === 'support') {
      setStep('mfa-input')
      return
    }
    const response = await mutation.mutateAsync({
      accountToken,
      method: selected,
    })
    if (response.status === 'error') {
      if (response.errors?.some(e => /expired/i.test(e.message))) {
        toast.error(
          'It looks like you waited too long to select an option. Please try again.',
        )
        setStep('account-address')
      } else {
        setError(response)
      }
    } else {
      setStep('mfa-input')
    }
  }
  return (
    <ValidatedForm onValidSubmit={handleSubmit}>
      <InfoBox>
        <p>
          Please choose from the following authentication options that are
          available for your FedEx account.{' '}
          <FvLinkButton
            href={`${marketingUri}/fedex-integration#mfa`}
            target="_blank"
            theme="callout"
          >
            Learn more
          </FvLinkButton>
        </p>
      </InfoBox>
      <FedexError response={error} />
      <InputGroup
        inputType="radio"
        label="Please choose one:"
        inputProps={{
          orientation: 'vertical',
          className: '!h-auto',
          name: 'option',
          onChange: e => setSelected(e.target.value as MfaRequestType),
          value: selected,
          options: allOptions.map(o => ({
            label: labelGenerator(o),
            value: o.type,
          })),
        }}
      />
      <div className="flex justify-end">
        <FvButton theme="plain" onClick={() => setStep('account-address')}>
          Back
        </FvButton>
        <FvButton theme="primary" type="submit" loading={mutation.isLoading}>
          Continue
        </FvButton>
      </div>
    </ValidatedForm>
  )
}
